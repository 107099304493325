@import "../styles.base.pcss";

:root {
  --primary: #a6664e;
  --secondary: #d1bfa7;
  --text: #3c3b3b;
  --text-alt: #ffffff;
  --background: #f4f4f4;
  --foreground: #ffffff;
  --border-color: rgba(160, 160, 160, 0.3);
  --header-background: var(--primary);
  --aside-text: #e8e8e6;
  --aside-text-active: #ffffff;
  --aside-background: #68686b;
  --aside-background-active: #555555;
  --aside-background-active-state: #3e3e40;
}

.docs {
  main {
    > header[role="banner"] {
      color: var(--text-alt);
      a:any-link {
        color: var(--text-alt);
      }
    }
  }
}
