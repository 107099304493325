:root {
  --generic-border: rgba(160, 160, 160, 0.65);
}
::selection {
  color: var(--text-alt);
  background: var(--primary);
}
a:any-link {
  color: var(--text);
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  text-decoration: none;
}
a:hover,
a:focus,
a:active,
.active {
  text-decoration: none;
}
:not(pre) > code {
  color: var(--primary);
}
table {
  thead,
  tr {
    border-bottom-color: var(--border-color);
  }
  tr:hover {
    background-color: var(--secondary);
  }
}
.page {
  color: var(--text);
  a:hover,
  a:focus,
  a:active,
  .active {
    color: var(--primary);
  }
  background: var(--background);
  header[role="banner"] {
    background: var(--foreground);
    border-bottom: solid 1px var(--border-color);
    h1 {
      color: var(--text);
      a {
        border-right-color: var(--border-color);
      }
    }
    .links {
      a:not(:first-of-type)::before {
        content: "";
        position: absolute;
        top: 0.75rem;
        bottom: 0.75rem;
        left: -0.15rem;
        width: 3px;
        border-right-color: var(--border-color);
      }
      a:after {
        background: var(--primary);
      }
    }
    .search {
      .search-button {
        .search-submit {
          filter: contrast(0.2);
        }
        &:hover {
          .search-submit {
            filter: contrast(0);
          }
        }
      }
    }
  }
  main {
    article,
    .search,
    > footer {
      background: var(--foreground);
      border: solid 1px var(--border-color);
      :not(header) {
        a:any-link {
          border-bottom: 1px solid var(--generic-border);
        }
      }
      a:hover,
      a:focus,
      a:active,
      .active {
        border-bottom-color: transparent;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        a:any-link {
          border-bottom: 0;
        }
      }
      header {
        h1,
        h2 {
          position: relative;
          &:after {
            content: " ";
            position: absolute;
            top: 90%;
            left: 50%;
            width: 100%;
            border-bottom: 4px solid var(--border-color);
            transform: translateX(-50%);
          }
        }
      }
    }
    aside {
      header {
        svg {
          fill: var(--text);
        }
      }
    }
  }
  .drawer {
    background: var(--foreground);
    border-left: solid 1px var(--border-color);
  }
}
.listing {
  main {
    aside {
      section {
        article {
          header {
            h1,
            h2 {
              &:after {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}

.book,
.book.listing {
  main {
    article {
      header {
        h2,
        .metadata {
          &:after {
            border-bottom: 0;
          }
          a {
            border-bottom: 1px solid var(--border-color);
          }
        }
      }
    }
  }
}

.sequence {
  main {
    article {
      background: transparent;
      border: 0;
      [role="list"] {
        background: var(--foreground);
        border: 1px solid var(--border-color);
        [role="listitem"] {
          border: 1px solid var(--border-color);
          [itemprop="url"] {
            font-family: var(--OpenSans);
          }
        }
      }
    }
  }
}

.page main > footer header h1:after,
.page main > footer header h2:after,
.page main article header h1:after,
.page main article header h2:after .sequence {
  main {
    header {
      background: var(--foreground);
      border: solid 1px var(--border-color);
      a:any-link {
        border-bottom: 1px solid var(--generic-border);
      }
      a:hover,
      a:focus,
      a:active,
      .active {
        border-bottom-color: transparent;
      }
      h1,
      h2 {
        position: relative;
        &:after {
          content: " ";
          position: absolute;
          top: 90%;
          left: 50%;
          width: 100%;
          border-bottom: 4px solid var(--border-color);
          transform: translateX(-50%);
        }
        a:any-link {
          border-bottom: 0;
        }
      }
    }
    article {
      background: transparent;
      border-color: transparent;
      [role="list"] {
        [role="listitem"] {
          a {
            background: var(--foreground);
            border: solid 1px var(--border-color);
          }
        }
      }
    }
  }
}

.docs {
  color: var(--text);
  main {
    background-color: var(--background);
    > header[role="banner"] {
      color: var(--aside-text);
      background-color: var(--header-background);
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
      a:any-link {
        color: var(--aside-text);
        filter: contrast(1.2);
      }
      a:hover,
      a:focus,
      a:active,
      .active {
        filter: contrast(1.5);
        text-decoration: underline;
      }
      .drawer {
        color: var(--text);
        a:any-link {
          filter: contrast(1.2);
          border-bottom: 1px solid transparent;
        }
        a:hover,
        a:focus,
        a:active {
          border-bottom: 1px solid var(--primary);
        }
      }
    }
    section,
    aside {
      a:any-link {
        filter: contrast(1);
      }
    }
    section {
      & > article {
        a:any-link {
          border-bottom: 1px solid var(--border-color);
        }
        a:hover,
        a:focus,
        a:active,
        .active {
          border-bottom-width: 2px;
        }
      }
    }
  }
  header[role="banner"] {
    form[role="search"] {
      input[type="search"] {
        color: var(--aside-text);
        &:focus {
          &::placeholder {
            opacity: 0.6;
          }
        }
        &::placeholder {
          color: var(--aside-text);
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus {
        color: var(--aside-text);
      }
    }
  }
  header[role="banner"],
  .search,
  .drawer,
  .sidebar {
    [role="button"] {
      svg {
        filter: contrast(1.2);
      }
      &:hover {
        svg {
          filter: contrast(1.5);
        }
      }
    }
  }
  > aside,
  .drawer {
    color: var(--aside-text);
    background-color: var(--aside-background);
    a:any-link {
      color: var(--aside-text-active);
    }
    a:hover,
    a:focus,
    a:active,
    .active {
      filter: contrast(1.2);
      text-decoration: underline;
    }
    header {
      background-color: var(--aside-background-active);
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
      h1 {
        color: var(--aside-text-active);
        filter: contrast(1.2);
      }
    }
    .menu {
      background-color: var(--aside-background);
      ol {
        li {
          &:before {
            color: var(--aside-text);
            opacity: 0.75;
          }
        }
      }
      .current-group {
        background-color: var(--aside-background-active);
      }
      .current {
        background-color: var(--aside-background-active-state);
      }
    }
    footer {
      color: var(--aside-text);
      svg {
        fill: var(--aside-text);
      }
    }
    footer[role="contentinfo"] {
      justify-content: flex-end;
      a:any-link {
        color: var(--aside-text);
        text-decoration: none;
        border-bottom: 1px solid var(--primary);
      }
      a:hover,
      a:focus,
      a:active,
      .active {
        text-decoration: none;
        border-bottom: 1px solid var(--primary);
      }
    }
  }
  img {
    border: solid 1px var(--border-color);
  }
  .next-previous {
    a:hover,
    a:focus,
    a:active,
    .active {
      text-decoration: underline;
    }
    article,
    article:first-of-type {
      border-color: var(--border-color);
    }
  }
  &.listing {
    article {
      > article {
        a:any-link {
          border-bottom: 0;
        }
        a:hover,
        a:focus,
        a:active,
        .active {
          color: var(--primary);
        }
        border-bottom: solid 1px var(--border-color);
      }
    }
  }
}

.cv {
  main {
    article {
      header {
        h2 {
          &:after {
            content: none;
          }
        }
      }
    }
  }
}

header[role="banner"] {
  form[role="search"] {
    input[type="search"] {
      color: var(--text);
      background: transparent;
      transition-delay: 0ms;
      box-shadow: none;
      &:focus {
        &::placeholder {
          opacity: 0.6;
        }
      }
      &::placeholder {
        opacity: 0;
        color: var(--text);
      }
    }
  }
}

header[role="banner"],
.search,
.drawer,
.sidebar {
  [role="button"] {
    svg {
      fill: var(--aside-text);
      filter: contrast(0.2);
      transition: fill 0.4s ease;
    }
    &:hover {
      svg {
        filter: contrast(0.5);
      }
    }
    &.search-close {
      svg {
        fill: var(--text);
      }
    }
  }
}
footer[role="contentinfo"] {
  a:any-link {
    border-bottom: 1px solid var(--primary);
  }
  a:hover,
  a:focus,
  a:active,
  .active {
    border-bottom: 1px solid var(--primary);
  }
}

/* Slider */
.swiper-container {
  .swiper-pagination-bullet-active,
  .swiper-pagination-progressbar-fill {
    background-color: var(--primary);
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: var(--primary);
    filter: contrast(1.2);
    &:hover {
      filter: contrast(1.5);
    }
  }
}

/* Notes */
.marginnote,
.sidenote {
  text-align: justify;
}
